import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField, Button, Box, Container, Typography, FormControl, InputLabel, Select, MenuItem, Snackbar, CircularProgress, FormControlLabel, Checkbox
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Header from '../Header/Header';
import { useUserId } from '../CommonComponents/UserIdProvider';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialState = (userId) => ({
  user: { id: userId },
  leaveType: { id: '' }, // Initialize with an empty string
  appliedDate: '',
  appliedEndDate: '',
  reason: '',
  status: 'Pending',
  lastActionBy: 'Admin',
  isFullDay: true,
  isHalfDay: false,
  halfDayPeriod: '',
});

function ApplyLeave() {
  const { userId } = useUserId();
  const [leaveDetails, setLeaveDetails] = useState(initialState(userId));
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ severity: 'info', message: '' });
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    const calculateTotalDays = () => {
      const startDate = new Date(leaveDetails.appliedDate);
      const endDate = new Date(leaveDetails.appliedEndDate);
      let diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1; // Including start date
      if (leaveDetails.isHalfDay) {
        diffInDays -= 0.5; // Subtract 0.5 if half-day leave is selected
      }
      setTotalDays(diffInDays);
    };

    if (leaveDetails.appliedDate && leaveDetails.appliedEndDate) {
      calculateTotalDays();
    }
  }, [leaveDetails.appliedEndDate, leaveDetails.appliedDate, leaveDetails.isFullDay, leaveDetails.isHalfDay]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/leaves/types`)
      .then(response => {
        if (response.data.data && response.data.data.length > 0) {
          setLeaveTypes(response.data.data);
        } else {
          setAlertInfo({ severity: 'warning', message: 'No leave types available.' });
          setOpen(true);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching leave types:', error);
        setAlertInfo({ severity: 'error', message: 'Failed to fetch leave types.' });
        setOpen(true);
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'isFullDay') {
      setLeaveDetails(prevState => ({
        ...prevState,
        isFullDay: checked,
        isHalfDay: !checked,
        halfDayPeriod: '' // Reset half-day period if it's full day
      }));
    } else if (name === 'isHalfDay') {
      setLeaveDetails(prevState => ({
        ...prevState,
        isHalfDay: checked,
        isFullDay: !checked
      }));
    } else if (name === 'halfDayPeriod') {
      setLeaveDetails(prevState => ({
        ...prevState,
        halfDayPeriod: value
      }));
    } else {
      setLeaveDetails(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const appliedDate = new Date(leaveDetails.appliedDate);
    const appliedEndDate = new Date(leaveDetails.appliedEndDate);
    const diffInDays = Math.abs(appliedEndDate - appliedDate) / (1000 * 60 * 60 * 24) + 1; // Including start date
    const updatedLeaveDetails = {
      ...leaveDetails,
      totalDays: leaveDetails.isFullDay ? diffInDays : diffInDays - 0.5 // Adjusting for half-day
    };

    axios.post(`${process.env.REACT_APP_API_URL}/api/leaves/create`, updatedLeaveDetails)
      .then(response => {
        setAlertInfo({ severity: 'success', message: 'Leave created successfully.' });
        setOpen(true);
        setLeaveDetails(initialState(userId));
      })
      .catch(error => {
        console.error('Error applying for leave:', error);
        setAlertInfo({ severity: 'error', message: 'Failed to apply for leave.' });
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Header />
      <Box sx={{ p: 5, mr: '18%', mt: 12, ml: 45, mb: 8, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
        <Container component="main" maxWidth="sm">
          <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }} >Apply for Leave</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="appliedDate"
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={leaveDetails.appliedDate}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="appliedEndDate"
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={leaveDetails.appliedEndDate}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox checked={leaveDetails.isFullDay} onChange={handleChange} name="isFullDay" />}
              label="Full Day"
            />
            <FormControlLabel
              control={<Checkbox checked={leaveDetails.isHalfDay} onChange={handleChange} name="isHalfDay" />}
              label="Half Day"
            />
            {leaveDetails.isHalfDay && (
              <FormControl fullWidth margin="normal">
                <InputLabel id="half-day-period-label">Half Day Period</InputLabel>
                <Select
                  labelId="half-day-period-label"
                  id="halfDayPeriod"
                  name="halfDayPeriod"
                  value={leaveDetails.halfDayPeriod || ''} // Ensure a valid initial value
                  onChange={handleChange}
                >
                  <MenuItem value="First Half">First Half</MenuItem>
                  <MenuItem value="Second Half">Second Half</MenuItem>
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel id="leave-type-label">Leave Type</InputLabel>
              <Select
                labelId="leave-type-label"
                id="leaveType"
                name="leaveType"
                value={leaveDetails.leaveType.id || ''} // Ensure a valid initial value
                onChange={(event) => setLeaveDetails({ ...leaveDetails, leaveType: { id: event.target.value } })}
              >
                {leaveTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.leaveType}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              name="reason"
              label="Reason for Leave"
              multiline
              rows={4}
              value={leaveDetails.reason}
              onChange={handleChange}
            />
            <Typography>Total Days: {totalDays}</Typography>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Apply</Button>
          </form>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertInfo.severity} sx={{ bgcolor: '#009688' }}>{alertInfo.message}</Alert>
          </Snackbar>
        </Container>
      </Box>
    </div>
  );
}

export default ApplyLeave;
