import React, { useState, useEffect } from 'react';
import axios from 'axios';
import userAvatar from '../../assets/images/human_avatar.jpg';
import { Box, Grid, Paper, Typography, Select, MenuItem, Avatar, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Badge, Fab, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, FormControl, InputLabel } from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import {
  Event as EventIcon,
  Flag as FlagIcon,
  NightsStay as NightsStayIcon,
  ColorLens as ColorLensIcon,
  Mood as MoodIcon,
  AccessibilityNew as AccessibilityNewIcon,
  Work as WorkIcon,
  Cake as CakeIcon,
  LocalFireDepartment as LocalFireDepartmentIcon,
  Festival as FestivalIcon,
  Stars as StarsIcon,
  SportsMartialArts as SportsMartialArtsIcon
} from '@mui/icons-material';
import Header from '../Header/Header';
// import Footer from '../Footer/Footer';
import { getTotalEmployees } from '../CommonAPIs/API.js';
import { useUserId } from '../CommonComponents/UserIdProvider.js';
import { Link } from 'react-router-dom';
import { useUserDetails } from '../CommonComponents/UserDetailsProvider';

const Dashboard = () => {
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [remainingLeaves, setRemainingLeaves] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [upcomingHolidays, setUpcomingHolidays] = useState([]);
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const { userId } = useUserId();
  const [leaveData, setLeaveData] = useState({
    Approved: { count: 0, total_days: 0 },
    Rejected: { count: 0, total_days: 0 },
    Pending: { count: 0, total_days: 0 }
  });
  const [pendingLeaveCount, setPendingLeaveCount] = useState(0);
  const [assignedAssetCount, setAssignedAssetCount] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { userDetails } = useUserDetails();
  const [selectedUser, setSelectedUser] = useState(userId);
  const showAllUsers = userDetails.role.userRoles.showAllUsers;
  const [reminders, setReminders] = useState([]);
  const [totalReminders, setTotalReminders] = useState(0);

  useEffect(() => {
    const fetchStaticData = async () => {
      try {
        const holidayData = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/upcomingHolidays`);
        setUpcomingHolidays(holidayData.data.data);

        const birthdayData = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/upcomingBirthdays`);
        setUpcomingBirthdays(birthdayData.data.data);
      } catch (error) {
        console.error('Error fetching static data:', error);
      }
    };
    fetchStaticData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("userId: " + userId);

        const totalData = await getTotalEmployees();
        setTotalEmployees(totalData.data.length || 0);
        setUserProfiles(totalData.data);
        console.log("setUserProfiles: ", totalData.data);

        const remainingLeaves = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaves/remainingLeaves/${userId}`);
        console.log("Remaining Leaves: ", remainingLeaves.data.data);
        setRemainingLeaves(remainingLeaves.data.data);

        const pendingCount = getPendingLeaveCount(totalData.data, userId);
        setPendingLeaveCount(pendingCount);

        const assignedAssetCount = getAssignedAssetCount(totalData.data, userId);
        setAssignedAssetCount(assignedAssetCount);

        const reminders = getReminders(totalData.data, userId);
        setReminders(reminders);
        setTotalReminders(reminders.length);

      } catch (error) {
        //console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]); // updated dependency array

  useEffect(() => {
    const fetchLeaveStatisticsData = async () => {
      try {
        await fetchLeaveStatistics(selectedUser, selectedYear, selectedMonth);
      } catch (error) {
        console.error('Error fetching leave statistics:', error);
      }
    };
  
    if (selectedUser) {
      fetchLeaveStatisticsData();
    }
  }, [selectedUser, selectedMonth, selectedYear]);
  
  const handleUserChange = async (event) => {
    const selectedUserId = event.target.value;
    setSelectedUser(selectedUserId);
    try { 
      const pendingCount = getPendingLeaveCount(userProfiles, selectedUserId);
      setPendingLeaveCount(pendingCount);

      const assignedAssetCount = getAssignedAssetCount(userProfiles, selectedUserId);
      setAssignedAssetCount(assignedAssetCount);

      const reminders = getReminders(userProfiles, selectedUserId);
      setReminders(reminders);
      setTotalReminders(reminders.length);

      const remainingLeaves = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaves/remainingLeaves/${selectedUserId}`);
      console.log("Remaining Leaves: ", remainingLeaves.data.data);
      setRemainingLeaves(remainingLeaves.data.data);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      setSelectedYear(currentYear);
      setSelectedMonth(currentMonth);
      // await fetchLeaveStatistics(selectedUserId, currentYear, currentMonth);
    } catch (error) {
      console.error('Error fetching user data on change:', error);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const customScrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  };
  const COLORS = ['#00a65a', '#f56954', '#ffc107'];
  const holidayIconMap = {
    'New Year Day': EventIcon,
    'Republic Day': FlagIcon,
    'Maha Shivaratri': NightsStayIcon,
    'Holi': ColorLensIcon,
    'Good Friday': MoodIcon,
    'Ambedkar Jayanti': AccessibilityNewIcon,
    'Labor Day': WorkIcon,
    'Independence Day': FlagIcon,
    'Ganesh Chaturthi': CakeIcon,
    'Mahatma Gandhi Jayanti': CakeIcon,
    'Dussehra': LocalFireDepartmentIcon,
    'Diwali': FestivalIcon,
    'Guru Nanak Jayanti': StarsIcon,
    'Christmas Day': StarsIcon,
    'Boxing Day': SportsMartialArtsIcon
  };

  const getPendingLeaveCount = (userProfiles, selectedUserId) => {
    let pendingLeaveCount = 0;
    userProfiles.forEach(user => {
      if (user.id === selectedUserId) {
        user.leaves.forEach(leave => {
          if (leave.status === "Pending") {
            pendingLeaveCount++;
          }
        });
      }
    });
    return pendingLeaveCount;
  };

  const getAssignedAssetCount = (userProfiles, selectedUserId) => {
    let assignedAssetCount = 0;
    userProfiles.forEach(user => {
      if (user.id === selectedUserId) {
        assignedAssetCount = Array.isArray(user.userAssets) ? user.userAssets.length : user.userAssets.size;
      }
    });
    return assignedAssetCount;
  };

  const getReminders = (userProfiles, selectedUserId) => {
    let reminders = [];
    userProfiles.forEach(user => {
      if (user.id === selectedUserId) {
        console.log("Reminders::", user.reminders);
        reminders = Array.isArray(user.reminders) ? user.reminders : [];
      }
    });
    return reminders;
  };

  const fetchLeaveStatistics = async (targetUserId, year, month) => {
    try {
      const leaveStatisticsData = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaves/statistics?id=${targetUserId}&year=${year}&month=${month}`);

      if (leaveStatisticsData.status === 200 && leaveStatisticsData.data.data !== null) {
        setLeaveData({
          Approved: leaveStatisticsData.data.data.Approved || { count: 0, total_days: 0 },
          Rejected: leaveStatisticsData.data.data.Rejected || { count: 0, total_days: 0 },
          Pending: leaveStatisticsData.data.data.Pending || { count: 0, total_days: 0 }
        });
      } else {
        console.error('No leave statistics found for the specified month and year.');
        setLeaveData({
          Approved: { count: 0, total_days: 0 },
          Rejected: { count: 0, total_days: 0 },
          Pending: { count: 0, total_days: 0 }
        });
      }
    } catch (error) {
      console.error('Error fetching leave data:', error);
    }
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const isDataAvailable = leaveData.Approved.total_days !== 0 ||
    leaveData.Rejected.total_days !== 0 ||
    leaveData.Pending.total_days !== 0;

  return (
    <Box className="dashboard-container" sx={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f6f8', minHeight: '100vh', padding: '20px' }}>
      <Header />
      <Box className="dashboard-body" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'calc(100% - 185px)', marginLeft: '190px', mt: 10 }}>
        {showAllUsers === "yes" && (
          <Select
            value={selectedUser}
            onChange={handleUserChange}
            displayEmpty
            sx={{
              width: 250,
              bgcolor: 'background.paper',
              border: '1px solid #ced4da',
              borderRadius: '8px',
              padding: '4px 10px',
              color: '#333',
              transition: 'border-color 300ms ease, box-shadow 300ms ease',
              mb: '10px'
            }}
          >
            <MenuItem value=""><em>Select User</em></MenuItem>
            {userProfiles.map(user => (
              <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>
            ))}
          </Select>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858' }}>Total Employees</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#009688' }}>{totalEmployees}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858' }}>Pending Leave Requests</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#009688' }}>{pendingLeaveCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858' }}>Assigned Assets</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#009688' }}>{assignedAssetCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858' }}>Total Reminders</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#009688' }}>{totalReminders}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, height: '400px', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858', mb: 2, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>Leaves Overview (Current Month)</Box>
                <FormControl sx={{ minWidth: '120px', ml: 2 }}>
                  <InputLabel id="month-select-label">Month</InputLabel>
                  <Select labelId="month-select-label" value={selectedMonth} onChange={handleMonthChange}
                    sx={{
                      bgcolor: 'background.paper', border: '1px solid #ced4da', borderRadius: '8px',
                      transition: 'border-color 300ms ease, box-shadow 300ms ease',
                    }}>
                    {Array.from({ length: 12 }).map((_, idx) => (
                      <MenuItem key={idx + 1} value={idx + 1}>
                        {new Date(0, idx).toLocaleString('default', { month: 'long' })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: '80px', ml: 1 }}>
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select labelId="year-select-label" value={selectedYear} onChange={handleYearChange}
                    sx={{
                      bgcolor: 'background.paper', border: '1px solid #ced4da', borderRadius: '8px',
                      transition: 'border-color 300ms ease, box-shadow 300ms ease',
                    }} >
                    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Typography>
              <ResponsiveContainer width="100%" height="83%">
                {isDataAvailable ? (
                  <PieChart>
                    <Pie
                      data={[
                        { name: 'Approved', value: leaveData.Approved.total_days },
                        { name: 'Rejected', value: leaveData.Rejected.total_days },
                        { name: 'Pending', value: leaveData.Pending.total_days }
                      ]}
                      dataKey="value" cx="50%" cy="50%" outerRadius={110} fill="#009688" paddingAngle={5} >
                      {Object.keys(leaveData).map((key, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => `${value} Leaves`} />
                    <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                  </PieChart>
                ) : (
                  <div style={{ textAlign: 'center', padding: '100px' }}>
                    <p style={{ fontSize: '18px', color: '#666' }}>No data available for the selected period</p>
                  </div>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, height: '400px', overflowY: 'auto', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', ...customScrollbarStyles }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858', mb: 2 }}>Leave Requests</Typography>
              <TableContainer sx={{ maxHeight: 290, ...customScrollbarStyles }}>
                <Table sx={{ minWidth: 300 }}>
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#f7f7f7' }}>
                      <TableCell>Username</TableCell>
                      <TableCell>Date Range</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userProfiles.some(user => selectedUser ? user.id === selectedUser && user.leaves.length > 0 : user.id === userId && user.leaves.length > 0) ? (
                      userProfiles.map((user) => {
                        const showLeaves = selectedUser ? user.id === selectedUser : user.id === userId;
                        return showLeaves ? user.leaves.map((leave, index) => (
                          <TableRow key={`${user.id}-${index}`} sx={{
                            '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                            '&:hover': { backgroundColor: '#e0e0e0' },
                            '& > *': { py: 0.5 }
                          }}>
                            <TableCell>{user.fullName}</TableCell>
                            <TableCell>
                              {new Date(leave.appliedDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })} to
                              {new Date(leave.appliedEndDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                            </TableCell>
                            <TableCell>
                              <Badge
                                badgeContent={leave.status}
                                color={
                                  leave.status === 'Approved' ? 'success' :
                                    leave.status === 'Rejected' ? 'error' :
                                      'warning'
                                }
                              />
                            </TableCell>
                          </TableRow>
                        )) : null;
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">No Leave Requests found.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, height: '300px', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858', mb: 2 }}>Total Remaining Leaves</Typography>
              <Box sx={{ p: 2 }}>
                <Typography variant="subtitle2" sx={{ color: '#3c4858', mb: 1 }}>Remaining Leaves: {remainingLeaves.RemainingLeaves}</Typography>
                <Typography variant="subtitle2" sx={{ color: '#3c4858', mb: 1 }}>Casual Leaves: {remainingLeaves['Casual Leave']}</Typography>
                <Typography variant="subtitle2" sx={{ color: '#3c4858', mb: 1 }}>Sick Leaves: {remainingLeaves['Sick Leave']}</Typography>
                <Typography variant="subtitle2" sx={{ color: '#3c4858', mb: 1 }}>Earned Leaves: {remainingLeaves['Earned Leave']}</Typography>
                <Link to="/applyLeave" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" sx={{ padding: '7px', bgcolor: '#009688', '&:hover': { bgcolor: '#00796b' } }} color="primary">Apply Leave</Button>
                </Link>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, height: '300px', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', overflowY: 'auto', ...customScrollbarStyles }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858', mb: 2 }}>Upcoming Holidays</Typography>
              {upcomingHolidays.map((holiday) => (
                <Box key={holiday.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar sx={{ mr: 1, fontSize: '20px', bgcolor: '#009688', color: 'white' }}>
                    {React.createElement(holidayIconMap[holiday.name], {
                      style: { fontSize: 17 }
                    })}
                  </Avatar>
                  <Typography sx={{ fontSize: '0.875rem', color: '#3c4858' }}>{holiday.name} - {new Date(holiday.date).toLocaleDateString('en-GB', {
                    day: 'numeric', month: 'long', year: 'numeric'
                  })}</Typography>
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, height: '300px', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', overflowY: 'auto', ...customScrollbarStyles }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858', mb: 2 }}>Upcoming Birthdays</Typography>
              {upcomingBirthdays.map((user) => (
                <Box key={user.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar src={userAvatar} sx={{ width: 40, height: 40, mb: 1, mr: 2 }} />
                  <Typography sx={{ fontSize: '0.875rem', color: '#3c4858' }}>{user.fullName} - {new Date(user.dateOfBirth).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long'
                  })}</Typography>
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, height: '350px', overflowY: 'auto', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', ...customScrollbarStyles }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858' }}>Your Reminders</Typography>
                <Link to="/createReminder" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" sx={{ bgcolor: '#009688', '&:hover': { bgcolor: '#00796b' } }} color="primary">Create Reminder</Button>
                </Link>
              </Box>
              <TableContainer sx={{ maxHeight: 230, ...customScrollbarStyles }}>
                <Table sx={{ minWidth: 250 }}>
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#f7f7f7' }}>
                      <TableCell>Description</TableCell>
                      <TableCell>Frequency</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reminders.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={3} align="center">No reminders found.</TableCell>
                      </TableRow>
                    ) : (
                      reminders.map((reminder) => (
                        <TableRow key={reminder.id} sx={{
                          '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                          '&:hover': { backgroundColor: '#e0e0e0' },
                          '& > *': { py: 0.5 }
                        }}>
                          <TableCell>{reminder.description}</TableCell>
                          <TableCell>{reminder.repeatFrequency}</TableCell>
                          <TableCell>
                            <Badge badgeContent={reminder.active ? 'Active' : 'Inactive'} color={reminder.active ? 'success' : 'error'} />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

            </Paper>
          </Grid>
          {userDetails.role.userRoles.employeeProfilesDashboard === 'yes' && (
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 3, height: '300px', borderRadius: '16px', backgroundColor: '#fff', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', overflowY: 'auto', ...customScrollbarStyles, mb: 5 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#3c4858', mb: 2 }}>Employee Profiles</Typography>
                <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
                  {userProfiles.map((profile) => (
                    <Box key={profile.id} sx={{ minWidth: 220, bgcolor: 'background.paper', p: 2, borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                      <Avatar src={userAvatar} sx={{ width: 50, height: 50, mb: 1 }} />
                      <Typography variant="subtitle2" sx={{ color: '#2c3e50', fontWeight: 'bold' }}>{profile.fullName}</Typography>
                      <Typography variant="body2" sx={{ color: '#4a4a4a' }}>{profile.department.name}</Typography>
                    </Box>
                  ))}
                </Stack>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* <Footer /> */}

      {/* Quick Action Button */}
      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16, bgcolor: '#009688', '&:hover': { bgcolor: '#00796b' } }} onClick={handleDialogOpen}>
        <AddIcon />
      </Fab>

      {/* Quick Action Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          Quick Action
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField autoFocus margin="dense" label="Title" fullWidth variant="outlined" />
          <TextField margin="dense" label="Description" fullWidth multiline rows={4} variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleDialogClose} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;