import React, { createContext, useContext, useState, useEffect } from 'react';

const UserDetailsContext = createContext();

export const UserDetailsProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(() => {
        const storedUserDetails = localStorage.getItem('userDetails');
        return storedUserDetails ? JSON.parse(storedUserDetails) : null;
    });

    useEffect(() => {
        if (userDetails !== null) { 
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
        } else {
            localStorage.removeItem('userDetails');
        }
    }, [userDetails]);

    return (
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
            {children}
        </UserDetailsContext.Provider>
    );
};

export const useUserDetails = () => useContext(UserDetailsContext);
