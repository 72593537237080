import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from '../Header/Header';
import axios from 'axios';

import CustomDialog from '../CommonComponents/CustomDialog';


// Styling for table cells in the header row
const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#757575', // Chosen grey for headers
    color: '#ffffff', // White text for contrast
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: '10px 15px',
    borderBottom: '2px solid #ffffff' // Slightly lighter grey border
}));

// Styling for table cells in the body
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#ffffff', // White for body cells
    color: '#757575', // Grey text for a subtle contrast
    padding: '12px 15px',
    fontSize: 13,
    fontWeight: 'bold',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    borderBottom: '1px solid #e0e0e0' // Light grey border
}));

const StyledTableRow = styled(TableRow)({
    '&:hover': {
        backgroundColor: '#f5f5f5', // Light grey on hover for interactivity
    },
    cursor: 'pointer',
});

const CustomButton = styled(Button)({
    backgroundColor: '#9e9e9e', // Neutral grey for buttons
    color: '#ffffff', // White text
    fontSize: '0.875rem',
    padding: '6px 12px',
    fontWeight: 'bold',
    borderRadius: 4,
    textTransform: 'none',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&:hover': {
        backgroundColor: '#bdbdbd', // Lighter grey on hover
    },
    '&:disabled': {
        backgroundColor: '#e0e0e0', // Light grey when disabled
        color: '#bdbdbd',
    }
});

const DepartmentListPage = () => {
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchDepartments = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/department/getDepartmentInfo`);
            setDepartments(response.data.data);
        } catch (error) {
            console.error('Failed to fetch departments:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDeactivate = async (id) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/department/deactivate/${id}`);
            fetchDepartments();
            showDialog('Success', 'Department deactivated successfully!');
        } catch (error) {
            console.error('Failed to deactivate department:', error);
            showDialog('Error', 'Failed to deactivate department');
        }
    };
    const showDialog = (title, message) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleActivate = async (id) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/department/activate/${id}`);
            fetchDepartments();
            showDialog('Success', 'Department activated successfully!');
        } catch (error) {
            console.error('Failed to activate department:', error);
            showDialog('Error', 'Failed to activate department');
        }
    };
    return (
        <div>
            <Header />
            <TableContainer component={Paper} elevation={3} sx={{ maxWidth: '80%', margin: 'auto', mt: 12, mb: 4, ml: '15%', borderRadius: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledHeaderCell>ID</StyledHeaderCell>
                            <StyledHeaderCell>Name</StyledHeaderCell>
                            <StyledHeaderCell>Description</StyledHeaderCell>
                            <StyledHeaderCell>Status</StyledHeaderCell>
                            <StyledHeaderCell align="center">Action</StyledHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={5} style={{ textAlign: 'center', padding: '20px' }}>
                                    <CircularProgress color="inherit" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            departments.map((department) => (
                                <StyledTableRow key={department.id}>
                                    <StyledTableCell>{department.id}</StyledTableCell>
                                    <StyledTableCell>{department.name}</StyledTableCell>
                                    <StyledTableCell>{department.description}</StyledTableCell>
                                    <StyledTableCell>{department.status}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {department.status === 'Active' ? (
                                            <CustomButton
                                                variant="contained"
                                                onClick={() => handleDeactivate(department.id)}
                                            >
                                                Deactivate
                                            </CustomButton>
                                        ) : (
                                            <CustomButton
                                                variant="contained"
                                                onClick={() => handleActivate(department.id)}
                                            >
                                                Activate
                                            </CustomButton>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomDialog open={dialogOpen} title={dialogTitle} message={dialogMessage} onClose={handleCloseDialog} />

        </div>
    );
};

export default DepartmentListPage;
