import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Paper } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header';
import CustomDialog from '../CommonComponents/CustomDialog.js';

const AssignAssetsPage = () => {
    const [selectedUserId, setSelectedUserId] = useState('');
    const [users, setUsers] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assignedAssets, setAssignedAssets] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/getUserInfo`);
                setUsers(response.data.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchAssets = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/assets/getAssetInfo`);
                setAssets(response.data.data);
            } catch (error) {
                console.error('Error fetching assets:', error);
            }
        };
        fetchAssets();
    }, []);

    const handleUserChange = async (event) => {
        const userId = event.target.value;
        setSelectedUserId(userId);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}/assets`);
            setAssignedAssets(response.data.data.map(assignment => assignment.asset));
        } catch (error) {
            console.error('Error fetching user assets:', error);
        }
    };

    const handleToggleAsset = (assetId) => {
        if (assignedAssets.some(asset => asset.id === assetId)) {
            setAssignedAssets(prevAssets => prevAssets.filter(asset => asset.id !== assetId));
        } else {
            const assetToAdd = assets.find(asset => asset.id === assetId);
            if (assetToAdd) {
                setAssignedAssets(prevAssets => [...prevAssets, assetToAdd]);
            }
        }
    };

    const handleAssignAssets = async () => {
        try {
            if (!selectedUserId || assignedAssets.length === 0) {
                console.error('User or assets not selected');
                setDialogMessage('Please select a user and assets to assign.');
                setDialogOpen(true);
                return;
            }

            const unavailableAssets = assignedAssets.filter(asset => asset.availableQuantity <= 0);

            if (unavailableAssets.length > 0) {
                const unavailableAssetNames = unavailableAssets.map(asset => asset.name).join(', ');
                setDialogMessage(`The following assets are unavailable: ${unavailableAssetNames}`);
                setDialogOpen(true);
                return;
            }

            const assetIds = assignedAssets.map(asset => asset.id);

            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/${selectedUserId}/assignAssets`,  assetIds );

            setDialogMessage('Assets assigned successfully');
            setDialogOpen(true);
        } catch (error) {
            console.error('Error assigning assets to user:', error);
            setDialogMessage('An error occurred while assigning assets');
            setDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Header />
            <Grid container justifyContent="center">
                <Grid item xs={12} md={8} lg={6}>
                    <Paper elevation={3} sx={{ p: 3, mt: 12 }}>
                        <Typography variant="h5" align="center" gutterBottom>Assign Assets to User</Typography>
                        <FormControl fullWidth style={{ marginBottom: 16 }}>
                            <InputLabel id="user-select-label">Select User</InputLabel>
                            <Select
                                labelId="user-select-label"
                                id="user-select"
                                value={selectedUserId}
                                onChange={handleUserChange}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                            {assets.map((asset) => (
                                <FormControlLabel
                                    key={asset.id}
                                    control={<Checkbox checked={assignedAssets.some(a => a.id === asset.id)} onChange={() => handleToggleAsset(asset.id)} />}
                                    label={`${asset.name} (Available: ${asset.availableQuantity})`}
                                    style={{ margin: 8 }}
                                />
                            ))}
                        </Box>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={handleAssignAssets}>Assign Assets</Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <CustomDialog
                open={dialogOpen}
                title="Asset Assignment"
                message={dialogMessage}
                onClose={handleCloseDialog}
            />
        </div>
    );
};

export default AssignAssetsPage;
