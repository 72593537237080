import React, { createContext, useContext, useState, useEffect } from 'react';

const UserIdContext = createContext();

export const UserIdProvider = ({ children }) => {
    const [userId, setUserId] = useState(() => {
        // Retrieve userId from localStorage if available
        const storedUserId = localStorage.getItem('userId');
        // const storedUserDetails = localStorage.getItem('userDetails');
        return storedUserId ? JSON.parse(storedUserId) : null;
    });

    // Update localStorage when userId changes
    useEffect(() => {
        localStorage.setItem('userId', JSON.stringify(userId));
    }, [userId]);

    return (
        <UserIdContext.Provider value={{ userId, setUserId }}>
            {children}
        </UserIdContext.Provider>
    );
};

export const useUserId = () => useContext(UserIdContext);