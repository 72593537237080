import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    CircularProgress,
    Badge,
    Button,
    Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Header from '../Header/Header';
import { useUserId } from '../CommonComponents/UserIdProvider';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RemindersPage() {
    const { userId } = useUserId();
    const [reminders, setReminders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ severity: 'info', message: '' });

    const fetchReminders = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reminders/user/${userId}`);
            setReminders(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching reminders:', error);
            setAlertInfo({ severity: 'error', message: 'Failed to fetch reminders.' });
            setOpen(true);
            setLoading(false);
        }
    }, [userId]);

    const handleStatusChange = async (reminderId, newStatus) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reminders/updateStatus/${reminderId}`, { active: newStatus });
            if (response.data.statusCode === 200) {
                setAlertInfo({ severity: 'success', message: 'Reminder status updated successfully.' });
                fetchReminders(); // Refresh the list after status change
            } else {
                setAlertInfo({ severity: 'error', message: 'Failed to update reminder status.' });
            }
        } catch (error) {
            console.error('Error updating reminder status:', error);
            setAlertInfo({ severity: 'error', message: 'Error updating reminder status.' });
        }
        setOpen(true);
    };

    useEffect(() => {
        fetchReminders();
    }, [fetchReminders]);

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <Header />
            <Box sx={{
                p: 5,
                mr: '18%',
                mt: 12,
                ml: '25%',
                mb: 3,
                borderRadius: 10,
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff'
            }}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Your Reminders
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }}>Description</TableCell>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }} align="right">Task Date</TableCell>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }} align="right">Start Time</TableCell>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }} align="right">End Time</TableCell>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }} align="right">Frequency</TableCell>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }} align="right">Active</TableCell>
                                    <TableCell sx={{ p: 2, fontWeight: 'bold', whiteSpace: 'nowrap' }} align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reminders.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No reminders found.
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    reminders.map((reminder) => (
                                        <TableRow key={reminder.id}>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} component="th" scope="row">
                                                {reminder.description}
                                            </TableCell>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align="right">
                                                {new Date(reminder.taskDate).toLocaleString()}
                                            </TableCell>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align="right">
                                                {new Date(reminder.reminderStartTime).toLocaleString()}
                                            </TableCell>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align="right">
                                                {new Date(reminder.reminderEndTime).toLocaleString()}
                                            </TableCell>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align="right">
                                                {reminder.repeatFrequency}
                                            </TableCell>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align="right">
                                                <Badge badgeContent={reminder.active ? 'Yes' : 'No'} color={reminder.active ? 'success' : 'error'} />
                                            </TableCell>
                                            <TableCell sx={{ p: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align="center">
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: reminder.active ? '#e57373' : '#009688' }}
                                                    onClick={() => handleStatusChange(reminder.id, !reminder.active)}
                                                >
                                                    {reminder.active ? 'Deactivate' : 'Activate'}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={alertInfo.severity} sx={{ bgcolor: '#009688' }}>
                        {alertInfo.message}
                    </Alert>
                </Snackbar>

            </Box>
        </div>
    );
}

export default RemindersPage;
