import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Box, MenuItem, Select, CircularProgress, Avatar, Chip } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header';
import CustomDialog from '../CommonComponents/CustomDialog';
import { useParams } from 'react-router-dom';
import userAvatar from './../../assets/images/human_avatar.jpg';
 
import { getUserRoles, getDepartmentInfo, getUserInfo ,getTotalEmployees} from '../CommonAPIs/API';
 
const EmployeeCreationPage = () => {
    const { userId } = useParams();
    
    const [formData, setFormData] = useState({
        username: '', password: '', fullName: '',email:'', department: '', status: '', address: '',
        phoneNumber: '', dateOfBirth: '', gender: '', maritalStatus: '', joiningDate: '', remarks: '', roleId: '',
        panCardNumber: '', aadharCardNumber: '', employeeImageUrl: '', bankAccountNumber: '', bankName: '', ifscCode: '',
        reportingManager: '',emergencyContactName:'',emergencyContactNo:'',emergencyContactRelationship:'',confirmPassword:''
    });
 
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null); // Store file object
    const [imagePreview, setImagePreview] = useState('');
    const [assignedAssets, setAssignedAssets] = useState([]);
    const [users, setUsers] = useState([]);
    
    const [errors, setErrors] = useState({
        password:false,
        confirmPassword:false
    });
    const [isDisabled,setIsDisabled] = useState(false);
    const requiredFields = ['password','confirmPassword'];
    useEffect(() => {        
        const fetchData = async () => {
            setLoading(true);
            try {
                const [roleResponse, departmentResponse] = await Promise.all([
                    getUserRoles(), getDepartmentInfo()
                ]);
                setRoles(roleResponse.data);
                setDepartments(departmentResponse.data);
                if (userId) {
                    const userInfoResponse = await getUserInfo(userId);
                    setFormData(prev => ({
                        ...prev,
                        ...userInfoResponse.data[0],
                        department: userInfoResponse.data[0].department.department_id,
                        roleId: userInfoResponse.data[0].role.roleId,
                        employeeImageUrl: userInfoResponse.data[0].employeeImageUrl || '',
                        status: userInfoResponse.data[0].status || '', // Set status value
                        gender: userInfoResponse.data[0].gender || '', // Set gender value
                        maritalStatus: userInfoResponse.data[0].maritalStatus || '',
                        reportingManager: userInfoResponse.data[0].reportingManager || ''
 
                    }));
                    // setImagePreview(`http://localhost:8080/${userInfoResponse.data[0].employeeImageUrl}`);
                    if (userInfoResponse.data[0].employeeImageUrl) {
                        setImagePreview(`${process.env.REACT_APP_API_URL}/api/users/employeeImage/${userInfoResponse.data[0].employeeImageUrl}`);
                    }
                   
                    const loginUserId = localStorage.getItem('userId');
                    // console.log(userId + "  ===== " + loginUserId)
                    if(parseInt(userId) === parseInt(loginUserId)){
                        // console.log(userId + "  ===== " + loginUserId)
                        setIsDisabled(true);
                    }
                  
                    const assignedAssetsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}/assets`);
                    setAssignedAssets(assignedAssetsResponse.data.data.map(assignment => assignment.asset));
  
                 }
                
                const allUsersResponse = await getTotalEmployees();
                setUsers(allUsersResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setDialogMessage('Failed to fetch data');
                setDialogOpen(true);
            } finally {
                
                setLoading(false);
            }
        };
        fetchData();
    }, [userId]);


    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if(requiredFields.includes(name)){
            setErrors({
                ...errors,
                [name]:value ===''
            });
        }

        if (name === 'confirmPassword') {
            setErrors({
              ...errors,
              matchError: value !== formData.password,
            });
          }
    };
 
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
 
    const handleSubmit = async () => {
 
        const selectedRole = roles.find(role => role.roleId === formData.roleId);
        const selectedDepartment = departments.find(department => department.department_id === formData.department);
        const roleId = selectedRole ? selectedRole.roleId : null;
        const department_id = selectedDepartment ? selectedDepartment.department_id : null;
        
        const newErrors ={};
        requiredFields.forEach((field)=>{
            newErrors[field] = formData[field] ==='';
        });
        setErrors(newErrors);
        
        const selectedReportingManager = users.find(user => user.id === formData.reportingManager);
        const id = selectedReportingManager ? selectedReportingManager.id : null;
        let updatedFormData = { ...formData, role: { roleId }, department: { department_id }, reportingManager:{ id }};
 
        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));
        if (imageFile) {
            formDataToSend.append("file", imageFile);
        }
        const hasErrors = Object.values(newErrors).some(error =>error);
        if(!hasErrors && !errors.matchError){
            
        
        try {
            let response;
            console.log("userID::::" + userId);
            if (userId) {
                // Update user details
                console.log(updatedFormData);
 
                const updateUserUrl = `${process.env.REACT_APP_API_URL}/api/users/updateUser`;
                response = await axios.post(updateUserUrl, updatedFormData);
            } else {
                // Create new employee
                const createEmployeeUrl = `${process.env.REACT_APP_API_URL}/api/users/createEmployee`;
                response = await axios.post(createEmployeeUrl, updatedFormData);
            }
 
 
            if (imageFile) {
                const uploadImageUrl = `${process.env.REACT_APP_API_URL}/api/users/uploadEmployeeImage/${userId || response.data.id}`;
 
                // Construct FormData object and append file data
                const formDataToSend = new FormData();
                formDataToSend.append('file', imageFile); // Assuming imageFile is the file object
 
                // Make POST request with FormData object
                const uploadResponse = await axios.post(uploadImageUrl, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log("file:" + uploadResponse.data.data);
                updatedFormData = { ...updatedFormData, employeeImageUrl: uploadResponse.data.data };
 
            }
 
            // If image upload is successful, then upload the image
 
 
            setDialogMessage(response.data.message);
        } 
    catch (error) {
            console.log("ERROR:" + error);
            setDialogMessage('Failed to submit the form');
        }
    }else{
        console.log(" Fix the issues in the form submition some thing is missing:::");
        setDialogMessage('Failed to submit the form');
    }
        setDialogOpen(true);
        if (!userId) {
            setFormData({
                // username: '', password: '', fullName: '', department: '', status: '', address: '',
                // phoneNumber: '', dateOfBirth: '', gender: '', joiningDate: '', remarks: '', roleId: ''
                username: '', password: '', fullName: '',email:'', department: '', status: '', address: '',
                phoneNumber: '', dateOfBirth: '', gender: '', maritalStatus: '', joiningDate: '', remarks: '', roleId: '',
                panCardNumber: '', aadharCardNumber: '', employeeImageUrl: '', bankAccountNumber: '', bankName: '', ifscCode: '',
                reportingManager: '',emergencyContactName:'',emergencyContactNo:'',emergencyContactRelationship:'',confirmPassword:''
            });
            setImagePreview('');
            setImageFile(null);
            
        }
    };
 
 
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
 
    return (
        <div>
            <Header />
            <Box sx={{ p: 5, ml: '15%', mr: '5%', mt: 12, mb: 10, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Typography variant="h5" align="center" gutterBottom>
                    {userId ? 'Edit User Details' : 'Create Employee'}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                    {/* <Avatar src={imagePreview ? URL.createObjectURL(imagePreview) : userAvatar} sx={{ width: 90, height: 90 }} /> */}
                    <Avatar src={imagePreview || userAvatar} sx={{ width: 90, height: 90 }} />
 
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span" sx={{ mt: 2 }}>
                            Upload Image
                        </Button>
                    </label>
                </Box>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        {/* Personal Information Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Personal Information</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="fullName"  disabled={isDisabled} label="Full Name" fullWidth value={formData.fullName || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="username"  disabled={isDisabled} label="Username" fullWidth value={formData.username || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="dateOfBirth"  disabled={isDisabled} label="Date of Birth" type="date" fullWidth InputLabelProps={{ shrink: true }} value={formData.dateOfBirth || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="gender"  disabled={isDisabled} label="Gender" fullWidth value={formData.gender || ""} displayEmpty onChange={handleFormChange}>
                                <MenuItem value="" >--Select Gender--</MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="email"  disabled={isDisabled} label="Email" fullWidth value={formData.email || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="maritalStatus"  disabled={isDisabled} label="Marital Status" displayEmpty fullWidth value={formData.maritalStatus || ''} onChange={handleFormChange}>
                                <MenuItem value="">--Martial Status--</MenuItem>
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Divorced">Divorced</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="phoneNumber"  disabled={isDisabled} label="Phone Number" fullWidth value={formData.phoneNumber || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="address" disabled={isDisabled}  label="Address" fullWidth value={formData.address || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type='password' name="password"  disabled={isDisabled}  label="Password" fullWidth value={formData.password || ''} onChange={handleFormChange} error={errors.password} helperText={errors.password ? 'Password is required' : ''} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type='password' name="confirmPassword"  disabled={isDisabled}  label="Confirm Password" fullWidth value={formData.confirmPassword || ''} onChange={handleFormChange}  error={errors.confirmPassword || errors.matchError} helperText={errors.confirmPassword ? 'Confirm Password is Required':errors.matchError ? 'Passwords do not match' : ''}  />
                        </Grid>
 
                        {/* Professional Details Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Professional Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="department"  disabled={isDisabled} label="Department" displayEmpty fullWidth value={formData.department || ''} onChange={handleFormChange}>
                                <MenuItem value="">--Select Department--</MenuItem>
                                {departments.map(department => (
                                    <MenuItem key={department.department_id} value={department.department_id || ''}>{department.name}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="roleId"  disabled={isDisabled} displayEmpty label="Role" fullWidth value={formData.roleId || ''} onChange={handleFormChange}>
                                <MenuItem value="">--Select Role--</MenuItem>
                                {roles.map(role => (
                                    <MenuItem key={role.roleId} value={role.roleId || ''}>{role.roleName}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="joiningDate"  disabled={isDisabled} label="Joining Date" type="date" fullWidth InputLabelProps={{ shrink: true }} value={formData.joiningDate || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="status"  disabled={isDisabled} fullWidth  displayEmpty value={formData.status || 'Active'} onChange={handleFormChange}>
                                <MenuItem value="">--Employee Status--</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Select name="reportingManager"  disabled={isDisabled} displayEmpty label="Reporting Manager" fullWidth value={formData.reportingManager || ''} onChange={handleFormChange}>
                                <MenuItem value="">--Reporting Manager--</MenuItem>
                                {users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>{user.fullName || ''}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
 
                        {/* Identification Details Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Identification Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="aadharCardNumber"  disabled={isDisabled} label="Aadhar Card Number" fullWidth value={formData.aadharCardNumber || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="panCardNumber"  disabled={isDisabled} label="PAN Card Number" fullWidth value={formData.panCardNumber || ''} onChange={handleFormChange} />
                        </Grid>
 
                        {/* Bank Details Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Bank Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="bankAccountNumber"  disabled={isDisabled} label="Bank Account Number" fullWidth value={formData.bankAccountNumber || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="bankName"  disabled={isDisabled} label="Bank Name" fullWidth value={formData.bankName || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="ifscCode" disabled={isDisabled} label="IFSC Code" fullWidth value={formData.ifscCode || ''} onChange={handleFormChange} />
                        </Grid>

                         {/* Emergency Contact Details Section */}
                         <Grid item xs={12}>
                            <Typography variant="h6">Emergency Contact Person Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="emergencyContactName" disabled={isDisabled} label="Name" fullWidth value={formData.emergencyContactName || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="emergencyContactNo" disabled={isDisabled} label="Contact No" fullWidth value={formData.emergencyContactNo || ''} onChange={handleFormChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="emergencyContactRelationship" disabled ={isDisabled} label="Relationship" fullWidth value={formData.emergencyContactRelationship || ''} onChange={handleFormChange}/>
                        </Grid>
                        {/* Assigned Assets Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Assigned Assets</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {assignedAssets.map(asset => (
                                    <Chip key={asset.id} label={asset.name} style={{ marginRight: 8, marginBottom: 8 }} />
                                ))}
                            </Box>
                        </Grid>
                        {/* Additional Remarks Section */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Additional Remarks</Typography>
                            <TextField name="remarks" disabled={isDisabled} label="Remarks" multiline fullWidth rows={3} value={formData.remarks || ''} onChange={handleFormChange} />
                        </Grid>
                    </Grid>
                )}
 
                <Box mt={2} display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>{userId ? 'Update' : 'Submit'}</Button>
                </Box>
                <CustomDialog open={dialogOpen} title={userId ? 'User Update' : 'User Creation'} message={dialogMessage} onClose={handleCloseDialog} />
            </Box>
        </div>
    );
};
 
export default EmployeeCreationPage;