import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUserId } from './CommonComponents/UserIdProvider';
import { useUserDetails } from './CommonComponents/UserDetailsProvider';
import logo from '../assets/images/fastlaneLogo.png';
import backgroundImage from '../assets/images/login6.jpg';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setUserId } = useUserId();
    const { setUserDetails } = useUserDetails();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                method: 'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });

            if (!response.ok) {
                throw new Error('Invalid username or password');
            }
            const data = await response.json();
            const userId = data.data.id;
            const userDetails = {
                username: data.data.username,
                role: data.data.role
            };

            localStorage.setItem('userId', userId);
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            setUserId(userId);
            setUserDetails(userDetails);

            setUsername('');
            setPassword('');
            setError('');
            navigate('/dashboard');
        } catch (error) {
            setUsername('');
            setPassword('');
            setError(error.message);
        }
    };

    return (
        <div style={{ position: 'relative', height: '100vh', backgroundColor: '#f5f5f5' }}>
            {/* Background Container */}
            <div style={{
                position: 'absolute',
                top: 30,
                left: 0,
                width: '95%',
                height: '92%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                filter: 'blur(40px)'
            }}>
                <Container style={{ width: '1200px', height: '600px', display: 'flex', padding: 0, borderRadius: 20, boxShadow: '0 3px 10px rgba(0,0,0,0.2)', overflow: 'hidden' }}>
                    <Box style={{ flex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={backgroundImage} alt="backgroundImage" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Box style={{ flex: 1, padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={{ width: '80%', height: 'auto', borderRadius: '5px', marginBottom: '3rem' }} />
                        <Typography variant="h5" gutterBottom style={{ color: '#009688', fontWeight: 'bold' }}>
                            HRMS
                        </Typography>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            style={{ marginBottom: '1rem' }}
                            InputProps={{
                                startAdornment: (
                                    <PersonIcon style={{ marginRight: '8px', color: '#009688' }} />
                                ),
                            }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            style={{ marginBottom: '1rem' }}
                            InputProps={{
                                startAdornment: (
                                    <LockIcon style={{ marginRight: '8px', color: '#009688' }} />
                                ),
                            }}
                        />
                    </Box>
                </Container>
            </div>

            {/* Foreground Container */}
            <Container style={{ width: '950px', height: '500px', display: 'flex', padding: 0, borderRadius: 20, boxShadow: '0 3px 10px rgba(0,0,0,0.2)', overflow: 'hidden', backgroundColor: '#fff', position: 'relative', zIndex: 1, margin: 'auto', top: '50%', transform: 'translateY(-50%)' }}>
                <Box style={{ flex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={backgroundImage} alt="backgroundImage" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Box>
                <Box style={{ flex: 1, padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} alt="Logo" style={{ width: '80%', height: 'auto', borderRadius: '5px', marginBottom: '3rem' }} />
                    <Typography variant="h5" gutterBottom style={{ color: '#009688', fontWeight: 'bold' }}>
                        HRMS
                    </Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom: '1rem' }}
                        value={username}
                        onChange={handleUsernameChange}
                        InputProps={{
                            startAdornment: (
                                <PersonIcon style={{ marginRight: '8px', color: '#009688' }} />
                            ),
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom: '1rem' }}
                        value={password}
                        onChange={handlePasswordChange}
                        InputProps={{
                            startAdornment: (
                                <LockIcon style={{ marginRight: '8px', color: '#009688' }} />
                            ),
                        }}
                    />
                    {error && (
                        <Typography color="error" style={{ marginBottom: '1rem' }}>
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        style={{ backgroundColor: '#009688', color: '#fff', marginBottom: '1rem', padding: '0.75rem' }}
                        onClick={handleSubmit}
                    >
                        Sign In
                    </Button>
                    <Link href="/forgot-password" style={{ color: '#009688', textAlign: 'center', marginTop: '1rem' }}>
                        Forgot Password?
                    </Link>
                </Box>
            </Container>
        </div>
    );
};

export default LoginPage;
