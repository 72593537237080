import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Grid, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header';
import CustomDialog from '../CommonComponents/CustomDialog.js';

const AssetPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        totalQuantity: 0,
        status: 'Active'
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [quantityToUpdate, setQuantityToUpdate] = useState(0);
    const [assetHistoryDialogOpen, setAssetHistoryDialogOpen] = useState(false);
    const [selectedAssetHistories, setSelectedAssetHistories] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/assets/getAssetInfo`);
            setAssets(response.data.data);
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/assets/createAsset`, formData);
            if (response.status === 200) {
                setDialogMessage('Asset created successfully');
                setAssets([...assets, response.data]); // Update state with the new asset
            } else {
                setDialogMessage('Unknown error occurred');
            }
        } catch (error) {
            console.error('Error creating asset:', error);
            setDialogMessage('An error occurred while creating asset');
        } finally {
            setSubmitting(false);
            setDialogOpen(true);
            // Reset form after submission if needed
            setFormData({
                name: '',
                description: '',
                totalQuantity: 0,
                status: 'Active'
            });
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        fetchData();
    };

    const handleStatusChange = async (index) => {
        try {
            const updatedAssets = [...assets];
            const assetToUpdate = updatedAssets[index];
            const newStatus = assetToUpdate.status === 'Active' ? 'Inactive' : 'Active';
            assetToUpdate.status = newStatus;
            updatedAssets[index] = assetToUpdate;
            setAssets(updatedAssets);

            const assetId = assetToUpdate.id;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/assets/updateStatus/${assetId}`, { status: newStatus });

            setDialogMessage(response.data.message);
        } catch (error) {
            console.error('Error updating asset status:', error);
            setDialogMessage('An error occurred while updating asset status');
        } finally {
            setDialogOpen(true);
        }
    };

    const handleOpenUpdateQuantityDialog = (asset) => {
        setSelectedAsset(asset);
        setQuantityToUpdate(0);
        setUpdateDialogOpen(true);
    };

    const handleUpdateQuantity = async () => {
        try {
            const updatedAsset = { ...selectedAsset, availableQuantity: selectedAsset.availableQuantity + quantityToUpdate,
                totalQuantity: selectedAsset.totalQuantity + quantityToUpdate
             };

            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/assets/updateQuantity/${selectedAsset.id}`, updatedAsset);
            console.log("RESPONSE", response);
            setDialogMessage('Quantity updated successfully');
        } catch (error) {
            console.error('Error updating quantity:', error);
            setDialogMessage('An error occurred while updating quantity');
        } finally {
            setUpdateDialogOpen(false);
            setDialogOpen(true);
            fetchData(); // Refresh asset list after updating quantity
        }
    };

    const handleOpenAssetHistoryDialog = (assetHistories) => {
        setSelectedAssetHistories(assetHistories);
        setAssetHistoryDialogOpen(true);
    };

    const handleCloseAssetHistoryDialog = () => {
        setAssetHistoryDialogOpen(false);
    };

    return (
        <div>
            <Header />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 5, ml: '30%', mr: '2%', mt: 12, mb: 10, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h5" align="center" gutterBottom>Create Asset</Typography>
                        <form>
                            <TextField name="name" label="Asset Name" fullWidth value={formData.name} onChange={handleFormChange} />
                            <TextField name="description" label="Description" fullWidth sx={{ mt: 2 }} value={formData.description} onChange={handleFormChange} />
                            <TextField name="totalQuantity" label="Total Quantity" type="number" fullWidth sx={{ mt: 2 }} value={formData.totalQuantity} onChange={handleFormChange} />
                            <Select name="status" label="Status" fullWidth value={formData.status} onChange={handleFormChange} sx={{ mt: 2 }}>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                            <Box mt={2} display="flex" justifyContent="center">
                                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={submitting} style={{
                                    backgroundColor: '#00796b',
                                    color: '#fff',
                                }}>
                                    {submitting ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 5, mr: '8%', mt: 12, mb: 8, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                        <Box sx={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                            <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold' }}>Assets List</Typography>
                            {loading ? (
                                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Asset Name</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Available Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 15 }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assets.map((asset, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{asset.name}</TableCell>
                                                    <TableCell>{asset.description}</TableCell>
                                                    <TableCell>{asset.totalQuantity}</TableCell>
                                                    <TableCell>{asset.availableQuantity}</TableCell>
                                                    <TableCell>{asset.status}</TableCell>
                                                    <TableCell>
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <Button
                                                                variant="contained"
                                                                // color={asset.status === 'Active' ? 'secondary' : 'primary'}
                                                                onClick={() => handleStatusChange(index)}
                                                                size="small" // Adjust button size
                                                                style={{ minWidth: '80px',backgroundColor: asset.status === 'Active' ? '#e57373' : '#009688', color: '#fff' }} // Set minimum width for consistency
                                                            >
                                                                {asset.status === 'Active' ? 'Deactivate' : 'Activate'}
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleOpenUpdateQuantityDialog(asset)}
                                                                size="small" // Adjust button size
                                                                style={{ minWidth: '80px' }} // Set minimum width for consistency
                                                            >
                                                                Update
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleOpenAssetHistoryDialog(asset.assetHistories)}
                                                                size="small" // Adjust button size
                                                                style={{ minWidth: '80px',backgroundColor: '#009688' }} // Set minimum width for consistency
                                                            >
                                                                History
                                                            </Button>
                                                        </div>
                                                    </TableCell>


                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Box>
                        <CustomDialog
                            open={dialogOpen}
                            title="Asset Actions"
                            message={dialogMessage}
                            onClose={handleCloseDialog}
                        />
                        <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
                            <DialogTitle>Update Quantity</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="currentTotalQuantity"
                                    label="Current Total Quantity"
                                    type="number"
                                    fullWidth
                                    value={selectedAsset ? selectedAsset.totalQuantity : ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="quantity"
                                    label="Quantity to Update"
                                    type="number"
                                    fullWidth
                                    value={quantityToUpdate}
                                    onChange={(e) => setQuantityToUpdate(parseInt(e.target.value))}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setUpdateDialogOpen(false)} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={handleUpdateQuantity} color="primary">
                                    Update Quantity
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={assetHistoryDialogOpen} onClose={handleCloseAssetHistoryDialog}>
                            <DialogTitle>Asset History</DialogTitle>
                            <DialogContent>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Quantity Change</TableCell>
                                                <TableCell>Change Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedAssetHistories.map((history, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{history.quantityChange}</TableCell>
                                                    <TableCell>{new Date(history.changeDate).toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseAssetHistoryDialog} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AssetPage;
