import React, { useState, useEffect } from 'react';
import {
    Button,
    Container,
    Typography,
    Card,
    CardContent,
    CardActions,
    Grid,
    Badge,
    Box,
    Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Header from '../Header/Header';
import { useUserId } from '../CommonComponents/UserIdProvider';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ManagerLeaveApproval() {
    const { userId } = useUserId();
    const [pendingLeaves, setPendingLeaves] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ severity: 'info', message: '' });

    useEffect(() => {
        const fetchPendingLeaves = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaves/pendingForManager/${userId}`);
                setPendingLeaves(response.data.data);
            } catch (error) {
                console.error('Error fetching pending leaves for approval:', error);
            }
        };
        fetchPendingLeaves();
    }, [userId]);

    const handleApprove = async (leaveId) => {
        await updateLeaveStatus(leaveId, 'Approved');
    };

    const handleReject = async (leaveId) => {
        await updateLeaveStatus(leaveId, 'Rejected');
    };

    const updateLeaveStatus = async (leaveId, status) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/leaves/updateStatus/${leaveId}`, {
                status,
                managerId: userId
            });
            if (response.data.statusCode === 200) {
                setAlertInfo({ severity: 'success', message: `Leave ${status.toLowerCase()} successfully.` });
                setPendingLeaves(prevLeaves => prevLeaves.filter(leave => leave.id !== leaveId));
            } else {
                setAlertInfo({ severity: 'error', message: 'Failed to update the leave status.' });
            }
            setOpen(true);
        } catch (error) {
            console.error(`Error updating leave status to ${status}:`, error);
            setAlertInfo({ severity: 'error', message: 'Error updating leave status.' });
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ ml: 10 }}>
            <Header />
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4, ml: 12 }}>
                <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 3, mt: '10px', fontSize: '24px', fontFamily: 'Arial' }}>
                    Manage Pending Leave Approvals
                </Typography>
                <Grid container spacing={3}>
                    {pendingLeaves.map((leave) => (
                        <Grid item xs={12} sm={6} md={4} key={leave.id}>
                            <Card raised sx={{ minWidth: 275, backgroundColor: '#f9f9f9', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '10px' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 16, color: '#555', fontWeight: 'bold', marginBottom: 1 }}>
                                        Requested by: <span style={{ color: '#333' }}>{leave.username}</span>
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, color: '#333', fontWeight: 'medium', marginBottom: 1 }} gutterBottom>
                                        {leave.leaveType} - {leave.reason}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>
                                        From: {new Date(leave.appliedDate).toLocaleDateString()}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>
                                        To: {new Date(leave.appliedEndDate).toLocaleDateString()}
                                    </Typography>
                                    <Typography sx={{ mt: 3, mr: 3, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        Days: {leave.totalDays}
                                        <Badge badgeContent={leave.status} color={leave.status === 'Pending' ? 'warning' : (leave.status === 'Approved' ? 'success' : 'error')} sx={{ '.MuiBadge-badge': { fontSize: '0.75rem', height: '20px', minWidth: '20px', backgroundColor: leave.status === 'Approved' ? '#4caf50' : (leave.status === 'Rejected' ? '#f44336' : '#ff9800') } }} />
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" style={{ backgroundColor: '#4caf50', color: 'white' }} onClick={() => handleApprove(leave.id)} sx={{ flexGrow: 1, mr: 0.5 }}>Approve</Button>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#e57373', color: 'white' }}
                                        onClick={() => handleReject(leave.id)}
                                        sx={{ flexGrow: 1, ml: 0.5 }}
                                    >
                                        Reject
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertInfo.severity} sx={{ bgcolor: '#009688' }}>
                    {alertInfo.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ManagerLeaveApproval;
