import React, { useState } from 'react';
import {
    TextField, Button, Box, Container, Typography, FormControl, InputLabel, Select, MenuItem, Snackbar, CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Header from '../Header/Header';
import { useUserId } from '../CommonComponents/UserIdProvider';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialState = (userId) => ({
    user: { id: userId },
    description: '',
    taskDate: '',
    repeatFrequency: 'ONCE',
    reminderStartTime: '',
    reminderEndTime: '',
    emailNotification: true, // Fixed value
    active: true // Fixed value
});

function ReminderForm() {
    const { userId } = useUserId();
    const [reminderDetails, setReminderDetails] = useState(initialState(userId));
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ severity: 'info', message: '' });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setReminderDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/reminders/create`, reminderDetails)
            .then(response => {
                setAlertInfo({ severity: 'success', message: 'Reminder created successfully.' });
                setOpen(true);
                setReminderDetails(initialState(userId));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error creating reminder:', error);
                setAlertInfo({ severity: 'error', message: 'Failed to create reminder.' });
                setOpen(true);
                setLoading(false);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Header />
            <Box sx={{ p: 5, mr: '18%', mt: 12, ml: 45, mb: 8, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Container component="main" maxWidth="sm">
                <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>Create Reminder</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="description"
                            label="Description"
                            type="text"
                            value={reminderDetails.description}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="taskDate"
                            label="Task Date"
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            value={reminderDetails.taskDate}
                            onChange={handleChange}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="repeatFrequency-label">Repeat Frequency</InputLabel>
                            <Select
                                labelId="repeatFrequency-label"
                                id="repeatFrequency"
                                name="repeatFrequency"
                                value={reminderDetails.repeatFrequency}
                                label="Repeat Frequency"
                                onChange={handleChange}
                            >
                                <MenuItem value="ONCE">Once</MenuItem>
                                <MenuItem value="DAILY">Daily</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="reminderStartTime"
                            label="Reminder Start Time"
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            value={reminderDetails.reminderStartTime}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="reminderEndTime"
                            label="Reminder End Time"
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            value={reminderDetails.reminderEndTime}
                            onChange={handleChange}
                            disabled={reminderDetails.repeatFrequency === 'ONCE'}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2,bgcolor: '#009688' }} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Create Reminder'}
                        </Button>
                    </form>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertInfo.severity} sx={{ bgcolor: '#009688' }}>{alertInfo.message}</Alert>
                    </Snackbar>
                </Container>
            </Box>
        </div>
    );
}

export default ReminderForm;
