import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Badge,
    IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import Header from '../Header/Header';
import { useUserId } from '../CommonComponents/UserIdProvider';

function LeaveList() {
    const { userId } = useUserId();
    const [leaves, setLeaves] = useState([]);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [selectedLeaveHistory, setSelectedLeaveHistory] = useState([]);

    const fetchLeaves = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaves/user/${userId}`);
            setLeaves(response.data.data);
        } catch (error) {
            console.error('Error fetching leaves:', error);
        }
    }, [userId]);

    useEffect(() => {
        fetchLeaves();
    }, [fetchLeaves]);

    const showLeaveHistory = (leave) => {
        setSelectedLeaveHistory(leave.leaveHistories);
        setOpenHistoryModal(true);
    };

    const handleApprove = async (leaveId) => {
        await updateLeaveStatus(leaveId, 'Approved', 'Your leave has been approved by your manager.');
    };

    const handleReject = async (leaveId) => {
        await updateLeaveStatus(leaveId, 'Rejected', 'Your leave has been rejected by your manager.');
    };

    const updateLeaveStatus = async (leaveId, status, reason) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/leaves/approve-reject/${leaveId}`, {
                status,
                reason,
                managerId: userId
            });
            if (response.data.statusCode === 200) {
                fetchLeaves(); // Refresh the list to show the updated status
            } else {
                alert('Failed to update the leave status.');
            }
        } catch (error) {
            console.error(`Error updating leave status to ${status}:`, error);
        }
    };

    return (
        <div>
            <Header />
            <Box sx={{ p: 5, mr: '18%', mt: 12, ml: '25%', mb: 8, borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Container maxWidth="md">
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        My Leaves
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ p: 2 }}>Leave Type</TableCell>
                                    <TableCell sx={{ p: 2 }}>From</TableCell>
                                    <TableCell sx={{ p: 2 }}>To</TableCell>
                                    <TableCell sx={{ p: 2 }}>Total Days</TableCell>
                                    <TableCell sx={{ p: 2 }}>Reason</TableCell>
                                    <TableCell sx={{ p: 2 }}>Status</TableCell>
                                    <TableCell sx={{ p: 2 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leaves.map((leave) => (
                                    <TableRow key={leave.id}>
                                        <TableCell sx={{ p: 2 }}>{leave.leaveType.leaveType}</TableCell>
                                        <TableCell sx={{ p: 2 }}>{new Date(leave.appliedDate).toLocaleDateString()}</TableCell>
                                        <TableCell sx={{ p: 2 }}>{new Date(leave.appliedEndDate).toLocaleDateString()}</TableCell>
                                        <TableCell sx={{ p: 2 }}>{leave.totalDays}</TableCell>
                                        <TableCell sx={{ p: 2 }}>{leave.reason}</TableCell>
                                        <TableCell sx={{ p: 2 }}>
                                            <Badge badgeContent={leave.status} color={leave.status === 'Approved' ? 'success' : leave.status === 'Rejected' ? 'error' : 'warning'} />
                                        </TableCell>
                                        <TableCell sx={{ p: 2 }}>
                                            <IconButton color="primary" onClick={() => showLeaveHistory(leave)}>
                                                <InfoIcon />
                                            </IconButton>
                                            {leave.status === 'Pending' && leave.reportingManagerId === userId && (
                                                <>
                                                    <Button variant="contained" color="success" onClick={() => handleApprove(leave.id)}>Approve</Button>
                                                    <Button variant="contained" sx={{ backgroundColor: '#ffcccc' }} onClick={() => handleReject(leave.id)}>Reject</Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
            <Dialog open={openHistoryModal} onClose={() => setOpenHistoryModal(false)} maxWidth="sm" fullWidth>
    <DialogTitle>Leave History</DialogTitle>
    <DialogContent dividers>
        {selectedLeaveHistory.length > 0 ? selectedLeaveHistory.map((history, index) => (
            <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {new Date(history.updatedOn).toLocaleString()}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    {history.updateStatus} by {history.updatedBy}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {history.description}
                </Typography>
            </Box>
        )) : <Typography>No history available.</Typography>}
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setOpenHistoryModal(false)} color="primary">Close</Button>
    </DialogActions>
</Dialog>

        </div>
    );
}

export default LeaveList;
