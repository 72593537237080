import React, { useState, useEffect, useRef, Suspense } from 'react';
import {
    AppBar, Typography, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Avatar, Collapse, IconButton, Box
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
    Dashboard, People, BusinessCenter, Assignment, ExpandLess, ExpandMore
} from '@mui/icons-material';
import logo from '../../assets/images/fastlaneLogo.png';

import userAvatar from '../../assets/images/human_avatar.jpg';
import { useUserId } from '../CommonComponents/UserIdProvider';
import { useUserDetails } from '../CommonComponents/UserDetailsProvider';
import { FaEye, FaPlus } from 'react-icons/fa';
const Header = () => {
   
    const { userId } = useUserId();
    const { userDetails } = useUserDetails();
    const [anchorEl, setAnchorEl] = useState(null);
    const isDropdownOpen = Boolean(anchorEl);
    const [openSubMenu, setOpenSubMenu] = useState({});
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const isMounted = useRef(true);
    const [logoLoaded, setLogoLoaded] = useState(false);
    const [logoError, setLogoError] = useState(false);
   
    
    useEffect(() => {
        return () => {
            isMounted.current = false;
            const img = new Image();
            img.src = logo;
            img.onload = () => {
                setLogoLoaded(true);
                console.log('Logo loaded successfully.');
            };
            img.onerror = () => {
                setLogoError(true);
                console.error('Error loading logo.');
            };
        };
    }, []);

    const handleUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubMenuClick = (menu) => {
        setOpenSubMenu(prev => ({ ...prev, [menu]: !prev[menu] }));
    };

    const handleMouseEnter = () => {
        setIsSidebarOpen(true);
    };

    const handleMouseLeave = () => {
        setIsSidebarOpen(false);
    };

    const handleLogout = async () => {
        try {
            // Call the logout API to invalidate the session
            // await axios.post('http://localhost:8080/auth/logout');
            // Clear user data from local storage
            // localStorage.removeItem('userId');

            localStorage.clear();

            // Redirect to login page and replace current history entry
            window.history.replaceState(null, '', '/');
            navigate('/'); // Redirect to login page
        } catch (error) {
            console.error('Error during logout', error);
        } finally {
            // Close user menu dropdown
            handleClose();
        }
    };

    const username = userDetails?.username || 'Guest';

    const sideBarEmployees = userDetails.role.userRoles.sideBarEmployees;
    const sideBarRoles = userDetails.role.userRoles.sideBarRoles;
    const sideBarDepartment = userDetails.role.userRoles.sideBarDepartment;
    const sideBarAssets = userDetails.role.userRoles.sideBarAssets;
    const sideBarLeaves = userDetails.role.userRoles.sideBarLeaves;
    const sideBarReminder = userDetails.role.userRoles.sideBarReminder;
    const sideBarDocument = userDetails.role.userRoles.sideBarDocument;
    console.log('Logo path:', logo);
    return (
        <>
            <AppBar position="fixed" sx={{
                backgroundColor: '#f8f9fa',
                color: 'black',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    { <div style={{ display: 'flex', alignItems: 'center' }} onMouseEnter={handleMouseEnter}>
                        <IconButton color="inherit">
                            <img src={logo} alt="Company Logo" style={{ height: '30px', marginRight: '10px' }} />
                        </IconButton>
                    </div> }
                    <Box sx={{ display: '-webkit-inline-box', alignItems: 'center' }} >
                        <IconButton color="inherit">
                            <Avatar src={userAvatar} alt="User Avatar" sx={{ width: 28, height: 28 }} />
                        </IconButton>
                        <Typography variant="body1" onClick={handleUserMenu}>
                            {username}
                        </Typography>
                        <Menu
                            anchorEl={anchorEl}
                            open={isDropdownOpen}
                            onClose={handleClose}
                            sx={{ mt: '25px' }}
                            PaperProps={{
                                style: {
                                    width: '150px'
                                }
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <Link to={`/employeeProfile/${userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    Profile
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Settings</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    width: isSidebarOpen ? '190px' : '60px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isSidebarOpen ? '190px' : '60px',
                        backgroundColor: '#808080',
                        color: '#fff',
                        overflowX: 'hidden',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        borderRight: 'none',
                        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.15)',
                        borderRadius: '0px 10px 10px 0px',
                        transition: 'width 0.3s ease-in-out',
                    },
                }}
            >
                <div style={{ marginTop: '64px' }}>
                    <List>
                        <ListItem button component={Link} to="/dashboard">
                            <ListItemIcon><Dashboard sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Dashboard" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                        </ListItem>

                        {sideBarEmployees === 'yes' && (
                            <ListItem button onClick={() => handleSubMenuClick('Employees')}>
                                <ListItemIcon><People sx={{ color: 'white' }} /></ListItemIcon>
                                <ListItemText primary="Employees" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                {openSubMenu['Employees'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                            </ListItem>
                        )}
                        <Collapse in={openSubMenu['Employees']} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                           
                                <ListItem button sx={{ pl: 4 }} component={Link} to="/employees">
                                <ListItemIcon><FaEye style={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="View Employees" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                </ListItem>
                                <ListItem button sx={{ pl: 4 }} component={Link} to="/createEmployees">
                                <ListItemIcon><FaPlus style={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Create Employee" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                </ListItem>
                            </List>
                        </Collapse>
                        {sideBarDocument === 'yes' && (
                            <ListItem button onClick={() => handleSubMenuClick('Document')}>
                                <ListItemIcon><People sx={{ color: 'white' }} /></ListItemIcon>
                                <ListItemText primary="Document" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                {openSubMenu['Document'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                            </ListItem>
                        )}
                        <Collapse in={openSubMenu['Document']} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                           
                                <ListItem button sx={{ pl: 4 }} component={Link} to="/document">
                                <ListItemIcon><FaEye style={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="View Document" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                </ListItem>
                                <ListItem button sx={{ pl: 4 }} component={Link} to="/createDocument">
                                <ListItemIcon><FaPlus style={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Create Document" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                </ListItem>
                            </List>
                        </Collapse>

                        {sideBarRoles === 'yes' && (
                            <>
                                <ListItem button onClick={() => handleSubMenuClick('Roles')}>
                                    <ListItemIcon><BusinessCenter sx={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Roles" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                    {openSubMenu['Roles'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                                </ListItem>
                                <Collapse in={openSubMenu['Roles']} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/createRoles">
                                            <ListItemText primary="Create Roles" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/assignRoles">
                                            <ListItemText primary="Assign Roles" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}

                        {sideBarDepartment === 'yes' && (
                            <>
                                <ListItem button onClick={() => handleSubMenuClick('Departments')}>
                                    <ListItemIcon><BusinessCenter sx={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Departments" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                    {openSubMenu['Departments'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                                </ListItem>
                                <Collapse in={openSubMenu['Departments']} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/departments">
                                            <ListItemText primary="View Departments" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/createDepartment">
                                            <ListItemText primary="Create Department" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}

                        {sideBarAssets === 'yes' && (
                            <>
                                <ListItem button onClick={() => handleSubMenuClick('Assets')}>
                                    <ListItemIcon><Assignment sx={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Assets" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                    {openSubMenu['Assets'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                                </ListItem>
                                <Collapse in={openSubMenu['Assets']} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/assignAssets">
                                            <ListItemText primary="Assign Assets" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/createAssets">
                                            <ListItemText primary="Create Assets" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}

                        {sideBarLeaves === 'yes' && (
                            <>
                                <ListItem button onClick={() => handleSubMenuClick('Leave')}>
                                    <ListItemIcon><Assignment sx={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Leave" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                    {openSubMenu['Leave'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                                </ListItem>
                                <Collapse in={openSubMenu['Leave']} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/applyLeave">
                                            <ListItemText primary="Apply Leave" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/leaveList">
                                            <ListItemText primary="Leave Status" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/leaveApprovalList">
                                            <ListItemText primary="Leave Approval" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        )}

                        {sideBarReminder === 'yes' && (
                            <>
                                <ListItem button onClick={() => handleSubMenuClick('Reminder')}>
                                    <ListItemIcon><Assignment sx={{ color: 'white' }} /></ListItemIcon>
                                    <ListItemText primary="Reminder" sx={{ fontFamily: 'Arial', fontSize: '16px', color: 'white' }} />
                                    {openSubMenu['Leave'] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                                </ListItem>
                                <Collapse in={openSubMenu['Reminder']} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/createReminder">
                                            <ListItemText primary="Create Reminder" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>
                                        <ListItem button sx={{ pl: 4 }} component={Link} to="/reminders">
                                            <ListItemText primary="View Reminders" sx={{ fontFamily: 'Arial', fontSize: '14px', color: 'white' }} />
                                        </ListItem>

                                    </List>
                                </Collapse>
                            </>
                        )}
                    </List>

                </div>
            </Drawer>
        </>
    );
};

export default Header;
