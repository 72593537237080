import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const CustomDialog = ({ open, title, message, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ backgroundColor: '#f0f2f5', color: '#333', textAlign: 'center', padding: '15px' }}>
        {title}
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#fff', padding: '10px' }}>
        <Typography variant="body1" align="center" gutterBottom>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#f0f2f5', padding: '15px', justifyContent: 'center' }}>
        <Button onClick={onClose} variant="contained" color="primary" size="small" style={{ padding: '4px 12px' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
