import React from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import EmployeeList from './components/Employee/EmployeeList';

// import Footer from './components/Footer/Footer';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import EmployeeCreationPage from './components/Employee/EmployeeCreationPage';
import DepartmentCreationPage from './components/Department/DepartmentCreationPage';
import DepartmentListPage from './components/Department/DepartmentListPage';

import CreateRolesPage from './components/Roles/CreateRolesPage';
import AssignRolesPage from './components/Roles/AssignRolesPage';
import LeaveList from './components/Leave/LeaveList';
import LeaveForm from './components/Leave/LeaveForm';
// import LoginNew from './components/LoginNew/LoginNew.js';
import { UserIdProvider } from './components/CommonComponents/UserIdProvider';
import { UserDetailsProvider } from './components/CommonComponents/UserDetailsProvider';


import EmployeeProfile from './components/Employee/EmployeeProfile';
import AssetCreationPage from './components/Assets/AssetCreationPage';
import AssignAssetsPage from './components/Assets/AssignAssetsPage';
import ManagerLeaveApproval from './components/Leave/ManagerLeaveApproval';
import ReminderForm from './components/Reminder/ReminderForm';
import RemindersPage from './components/Reminder/RemindersPage';

function App() {
    return (
        <Router>
            <UserIdProvider>
                <UserDetailsProvider>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/employees" element={<EmployeeList />} />
                        <Route path="/createEmployees" element={<EmployeeProfile />} />
                        <Route path="/updateUser/:userId" element={<EmployeeProfile />} />

                        <Route path="/departments" element={<DepartmentListPage />} />
                        <Route path="/createDepartment" element={<DepartmentCreationPage />} />

                        <Route path="/assignAssets" element={<AssignAssetsPage />} />
                        <Route path="/createAssets" element={<AssetCreationPage />} />

                        <Route path="/createRoles" element={<CreateRolesPage />} />
                        <Route path="/assignRoles" element={<AssignRolesPage />} />
                        {/* <Route path="/loginNew" element={<LoginNew />} /> */}

                        <Route path="/applyleave" element={<LeaveForm />} />
                        <Route path="/leaveList" element={<LeaveList />} />
                        <Route path="/leaveApprovalList" element={<ManagerLeaveApproval />} />
                        <Route path="/employeeProfile/:userId" element={<EmployeeProfile />} />

                        <Route path="/createReminder" element={<ReminderForm />} />
                        <Route path="/reminders" element={<RemindersPage />} /> 


                        {/* Add more routes here */}
                    </Routes>
                </UserDetailsProvider>
            </UserIdProvider>
            {/* <Footer /> */}
        </Router>
    );
}

export default App;
