import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import userAvatar from '../../assets/images/human_avatar.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './EmployeeList.css'; // Import the CSS file with traditional class names
import Header from '../Header/Header';
import { useNavigate } from 'react-router-dom';


const EmployeeList = () => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/getUserInfo`);
                const data = await response.json();
                setUsers(data.data || []);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    const handleShowModal = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const navigate = useNavigate();

    const UpdateUser = (userId) => {
        navigate(`/UpdateUser/${userId}`);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Header></Header>
            <div className="team-list">
                <h2 className="team-title">Team Members ({users.length})</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Phone Number</th>
                            <th>Address</th>
                            <th>Gender</th>
                            <th>Department</th>
                            <th>Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td><img src={user.gender === 'Male' ? userAvatar : userAvatar} alt="avatar" className="user-avatar" />{user.fullName}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.address}</td>
                                <td>{user.gender}</td>
                                <td>{user.dateOfBirth}</td>
                                <td className="button-container"><Button variant="link" className="three-dots" onClick={() => UpdateUser(user.id)}>Edit</Button>

                                    <Button variant="link" className="three-dots" onClick={() => handleShowModal(user)}>View More</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedUser && (
                            <>
                                <p><strong>ID:</strong> {selectedUser.id}</p>
                                <p><strong>Name:</strong> {selectedUser.fullName}</p>
                                <p><strong>Phone Number:</strong> {selectedUser.phoneNumber}</p>
                                <p><strong>Gender:</strong> {selectedUser.gender}</p>
                                <p><strong>Date of Birth:</strong> {selectedUser.dateOfBirth}</p>
                                <p><strong>Designation:</strong> {selectedUser.designation}</p>
                                <p><strong>Role:</strong> {selectedUser.role && selectedUser.role.roleName ? selectedUser.role.roleName : ' '}</p>
                                <p><strong>Department:</strong> {selectedUser.department.name}</p>
                                <p><strong>Joining Date:</strong> {selectedUser.joiningDate}</p>
                                <p><strong>Emergency Contact Name:</strong> {selectedUser.emergencyContactName}</p>
                                <p><strong>Emergency Contact Number:</strong> {selectedUser.emergencyContactNumber}</p>
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default EmployeeList;
