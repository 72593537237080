import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Box, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header';
import CustomDialog from '../CommonComponents/CustomDialog.js';

const DepartmentCreationPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: ''
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/department/createDepartment`, formData);
            if (response.status === 200) {
                setDialogMessage('Department created successfully!');
            } else {
                setDialogMessage('Unknown error occurred');
            }
        } catch (error) {
            if (error.response) {
                setDialogMessage(error.response.data.message || 'An error occurred while creating the department');
            } else {
                setDialogMessage('Network error occurred');
            }
        }
        setDialogOpen(true);
        resetForm();
    };

    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            status: ''
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Header />
            <Box sx={{ p: 5, ml: '15%', mr: '8%', mt: 12, mb: 10, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Typography variant="h5" align="center" gutterBottom>Create Department</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name="name" label="Department Name" fullWidth value={formData.name} onChange={handleFormChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="description" label="Description" fullWidth multiline rows={3} value={formData.description} onChange={handleFormChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            label="Status"
                            name="status"
                            fullWidth
                            value={formData.status}
                            onChange={handleFormChange}
                            displayEmpty
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
                <CustomDialog
                    open={dialogOpen}
                    title="Department Creation"
                    message={dialogMessage}
                    onClose={handleCloseDialog}
                />
            </Box>
        </div>
    );
};

export default DepartmentCreationPage;
