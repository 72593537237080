
import axios from 'axios';

// -----------GET USER INFO API------------------------------------------------------------
export const getTotalEmployees = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/getUserInfo`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching total employees:', error);
        throw error;
    }
};

export const getUserInfo = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/getUserInfo?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};



// -----------GET DEPARTMENT INFO API------------------------------------------------------------
export const getDepartmentInfo = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/department/getDepartmentInfo`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching Department Info:', error);
        throw error;
    }
};
// -----------GET ROLE INFO API------------------------------------------------------------

export const getUserRoles = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/roles/getUserRoles`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching User Roles Info:', error);
        throw error;
    }
};
// -----------COMMON API FOR CALLING POST APIS------------------------------------------------------------
export const fetchFromAPI = async (endpoint) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/${endpoint}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      throw error;
    }
  };
  