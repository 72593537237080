import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Box, MenuItem, Select, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header';
import CustomDialog from '../CommonComponents/CustomDialog.js';

const CreateRolesPage = () => {
    const [formData, setFormData] = useState({
        roleName: '',
        status: 'Active',
        createdAt: new Date().toISOString().split('T')[0]
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles/getUserRoles`);
                setUserRoles(response.data.data);
            } catch (error) {
                console.error('Error fetching user roles:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/roles/createRole`, formData);
            if (response.status === 200) {
                setDialogMessage('Role created successfully');
                setUserRoles([...userRoles, formData]); // Update state with the new role
            } else {
                setDialogMessage('Unknown error occurred');
            }
        } catch (error) {
            console.error('Error creating role:', error);
            setDialogMessage('An error occurred while creating role');
        }
        setDialogOpen(true);
        // Reset form after submission if needed
        setFormData({
            roleName: '',
            status: 'Active',
            createdAt: new Date().toISOString().split('T')[0]
        });
    };

    const handleStatusChange = async (index) => {
        try {
            const updatedRoles = [...userRoles];
            const roleToUpdate = updatedRoles[index];
            const newStatus = roleToUpdate.status === 'Active' ? 'Inactive' : 'Active';
            roleToUpdate.status = newStatus;
            updatedRoles[index] = roleToUpdate;
            setUserRoles(updatedRoles);

            // Use the roleId property from the role object
            const roleId = roleToUpdate.roleId;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/roles/updateStatus/${roleId}`, { status: newStatus });

            setDialogMessage(response.data.message);
        } catch (error) {
            console.error('Error updating role status:', error);
            setDialogMessage('An error occurred while updating role status');
        }
        setDialogOpen(true);
    };


    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Header />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 5, ml: '30%', mr: '2%', mt: 12, mb: 10, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h5" align="center" gutterBottom>Create Role</Typography>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <form>
                                <TextField name="roleName" label="Role Name" fullWidth value={formData.roleName} onChange={handleFormChange} />
                                <Select name="status" label="Status" fullWidth value={formData.status} onChange={handleFormChange} sx={{ mt: 2 }}>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                </Select>
                                <Box mt={2} display="flex" justifyContent="center">
                                    <Button variant="contained" color="primary" style={{ backgroundColor: '#009688' }} onClick={handleSubmit}>Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{ p: 5, mr: '8%', mt: 12, mb: 8, backgroundColor: '#FFFFFF', borderRadius: 10, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                        <Box sx={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                            <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold' }}>Roles List</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: 15 }}>Role Name</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: 15 }}>Status</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: 15 }}>Created At</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: 15 }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userRoles.map((role, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{role.roleName}</TableCell>
                                                <TableCell>{role.status}</TableCell>
                                                <TableCell>{role.createdAt}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        style={{ backgroundColor: role.status === 'Active' ? '#e57373' : '#009688', color: '#fff' }}
                                                        onClick={() => handleStatusChange(index)}
                                                    >
                                                        {role.status === 'Active' ? 'Deactivate' : 'Activate'}
                                                    </Button>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <CustomDialog
                            open={dialogOpen}
                            title="Role Creation"
                            message={dialogMessage}
                            onClose={handleCloseDialog}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default CreateRolesPage;
