import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Box, FormControlLabel, Switch, Paper, Container, MenuItem, Select, Card, CardContent } from '@mui/material';
import axios from 'axios';
import Header from '../Header/Header.js';
import CustomDialog from '../CommonComponents/CustomDialog.js';

const AssignRolesPage = () => {
    const [formData, setFormData] = useState({
        sno: '',
        roleId: '',
        employeeProfilesDashboard: false,
        showAllUsers: false,
        
        sideBarEmployees: false,
        sideBarRoles: false,
        sideBarDepartment: false,
        sideBarAssets: false,
        sideBarLeaves: false,
        sideBarReminder: false
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [roles, setRoles] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles/getUserRoles`);
            if (response.data && response.data.data) {
                setRoles(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching user roles:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFormChange = (e) => {
        const { value } = e.target;
        const selectedRole = roles.find(role => role.roleId === value);

        const newFormData = {
            roleId: value,
            sno: selectedRole && selectedRole.userRoles ? selectedRole.userRoles.sno : '',

            employeeProfilesDashboard: false,
            showAllUsers: false,
            sideBarEmployees: false,
            sideBarRoles: false,
            sideBarDepartment: false,
            sideBarAssets: false,
            sideBarLeaves: false,
            sideBarReminder: false
        };

        if (selectedRole && selectedRole.userRoles) {
            newFormData.employeeProfilesDashboard = selectedRole.userRoles.employeeProfilesDashboard === 'yes';
            newFormData.showAllUsers = selectedRole.userRoles.showAllUsers === 'yes';

            newFormData.sideBarEmployees = selectedRole.userRoles.sideBarEmployees === 'yes';
            newFormData.sideBarRoles = selectedRole.userRoles.sideBarRoles === 'yes';
            newFormData.sideBarDepartment = selectedRole.userRoles.sideBarDepartment === 'yes';
            newFormData.sideBarAssets = selectedRole.userRoles.sideBarAssets === 'yes';
            newFormData.sideBarLeaves = selectedRole.userRoles.sideBarLeaves === 'yes';
            newFormData.sideBarReminder = selectedRole.userRoles.sideBarReminder === 'yes';
        }

        setFormData(newFormData);
    };

    const handleToggleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        if (!formData.roleId) {
            setDialogMessage('Please select a role.');
            setDialogOpen(true);
            return;
        }

        const currentRole = roles.find(role => role.roleId === formData.roleId);

        const submitData = {
            roleId: formData.roleId,
            roleName: currentRole ? currentRole.roleName : '',
            status: currentRole ? currentRole.status : 'Active',
            createdAt: currentRole ? currentRole.createdAt : new Date().toISOString(),
            userRoles: {
                sno: formData.sno,
                employeeProfilesDashboard: formData.employeeProfilesDashboard ? 'yes' : 'no',
                showAllUsers: formData.showAllUsers ? 'yes' : 'no',

                sideBarEmployees: formData.sideBarEmployees ? 'yes' : 'no',
                sideBarRoles: formData.sideBarRoles ? 'yes' : 'no',
                sideBarDepartment: formData.sideBarDepartment ? 'yes' : 'no',
                sideBarAssets: formData.sideBarAssets ? 'yes' : 'no',
                sideBarLeaves: formData.sideBarLeaves ? 'yes' : 'no',
                sideBarReminder: formData.sideBarReminder ? 'yes' : 'no',
            }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/roles/assignRole/`, submitData);
            if (response.status === 200) {
                setDialogMessage('User Role Updated Successfully!');

                const userDetails = JSON.parse(localStorage.getItem('userDetails'));
                userDetails.role.userRoles = response.data.data;
         
                localStorage.setItem('userDetails', JSON.stringify(userDetails));
                console.log("Local Storage:", localStorage);
                fetchData();
            } else {
                setDialogMessage('Unknown error occurred');
            }
        } catch (error) {
            if (error.response) {
                setDialogMessage(error.response.data.message || 'An error occurred while assigning the user role');
            } else {
                setDialogMessage('Network error occurred');
            }
        }
        setDialogOpen(true);
        resetForm();
        fetchData();
    };

    const resetForm = () => {
        setFormData({
            roleId: '',
            employeeProfilesDashboard: false,
            showAllUsers: false,
            sideBarEmployees: false,
            sideBarRoles: false,
            sideBarDepartment: false,
            sideBarAssets: false,
            sideBarLeaves: false,
            sideBarReminder: false
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Header />
            <Container maxWidth="md">
                <Paper elevation={3} sx={{ p: 4, mt: 12, mb: 10,ml:10, borderRadius: 10 }}>
                    <Typography variant="h4" align="center" gutterBottom>Assign Roles</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Select
                                label="Role"
                                name="roleId"
                                value={formData.roleId}
                                onChange={handleFormChange}
                                fullWidth
                            >
                                <MenuItem value="">Select Role</MenuItem>
                                {roles.map(role => (
                                    <MenuItem key={role.roleId} value={role.roleId}>{role.roleName}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                {/* <CardHeader title="Dashboard" /> */}
                                <Typography variant="h6" sx={{ textAlign: 'center',fontWeight: 'bold', color: '#3c4858',mt:1 }}>Dashboard</Typography>

                                <CardContent>
                                    <Box mb={1}>
                                        <FormControlLabel
                                            control={<Switch checked={formData.employeeProfilesDashboard} onChange={(e) => handleToggleChange('employeeProfilesDashboard', e.target.checked)} />}
                                            label="Employee Profiles"
                                        />
                                    </Box>
                                    <Box mb={1}>
                                        <FormControlLabel
                                            control={<Switch checked={formData.showAllUsers} onChange={(e) => handleToggleChange('showAllUsers', e.target.checked)} />}
                                            label="Show All Users"
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                {/* <CardHeader title="Sidebar" /> */}
                                <Typography variant="h6" sx={{ textAlign: 'center',fontWeight: 'bold', color: '#3c4858',mt:1 }}>Sidebar</Typography>

                                <CardContent>
                                    {/* <Box mb={1}> */}
                                        <FormControlLabel
                                            control={<Switch checked={formData.sideBarEmployees} onChange={(e) => handleToggleChange('sideBarEmployees', e.target.checked)} />}
                                            label="Employees Section"
                                        />
                                    {/* </Box>
                                    <Box mb={1}> */}
                                        <FormControlLabel
                                            control={<Switch checked={formData.sideBarRoles} onChange={(e) => handleToggleChange('sideBarRoles', e.target.checked)} />}
                                            label="Roles Section"
                                        />
                                    {/* </Box>
                                    <Box mb={1}> */}
                                        <FormControlLabel
                                            control={<Switch checked={formData.sideBarDepartment} onChange={(e) => handleToggleChange('sideBarDepartment', e.target.checked)} />}
                                            label="Department Section"
                                        />
                                    {/* </Box>
                                    <Box mb={1}> */}
                                        <FormControlLabel
                                            control={<Switch checked={formData.sideBarAssets} onChange={(e) => handleToggleChange('sideBarAssets', e.target.checked)} />}
                                            label="Assets Section"
                                        />
                                    {/* </Box>
                                    <Box mb={1}> */}
                                        <FormControlLabel
                                            control={<Switch checked={formData.sideBarLeaves} onChange={(e) => handleToggleChange('sideBarLeaves', e.target.checked)} />}
                                            label="Leaves Section"
                                        />
                                    {/* </Box>
                                    <Box mb={1}> */}
                                        <FormControlLabel
                                            control={<Switch checked={formData.sideBarReminder} onChange={(e) => handleToggleChange('sideBarReminder', e.target.checked)} />}
                                            label="Reminder Section"
                                        />
                                    {/* </Box> */}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mt={2} display="flex" justifyContent="center">
                                <Button style={{ backgroundColor: '#009688'}}   variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                            </Box>
                        </Grid>

                        <CustomDialog
                            open={dialogOpen}
                            title="User Role Assignment"
                            message={dialogMessage}
                            onClose={handleCloseDialog}
                        />
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
};

export default AssignRolesPage;
